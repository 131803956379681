import axios from '@axios'
import axiosMultipart from '../../libs/axiosMultipart'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getVin(ctx, vin) {
      return new Promise((resolve, reject) => {
        axios
          .get(`backoffice/get-vin/${vin}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteVin(ctx, vin) {
      return new Promise((resolve, reject) => {
        axios
          .get(`backoffice/delete-vin/${vin}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getUser(ctx, user) {
      return new Promise((resolve, reject) => {
        axios
          .get(`backoffice/get-user/${user}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteUser(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`backoffice/delete-user/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    postVinCsv(ctx, formData) {
      return new Promise((resolve, reject) => {
        axiosMultipart
          .post('backoffice/import-csv',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
