import Vue from 'vue'

// axios
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { serviceApi } from '@microServicesConfig'

const headers = {
  'X-REQUEST-ID': uuidv4(),
  'Content-Type': 'multipart/form-data',
}

if (window.$cookies.get('accessToken') !== null) {
  headers.Authorization = `Bearer ${window.$cookies.get('accessToken')}`
}

//  console.log(headers)

/*
headers: {
    Authorization: `Bearer ${window.$cookies.get('accessToken')}`,
  },
*/

console.log(headers)

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: serviceApi,
  // timeout: 1000,
  headers,
})

//  auth image plugin -- not needed
//  axios.defaults.headers.common.Authorization = `Bearer ${window.$cookies.get('accessToken')}`

Vue.prototype.$http = axiosIns

export default axiosIns
